import { render, staticRenderFns } from "./jzmb1.vue?vue&type=template&id=907d6466&scoped=true&"
import script from "./jzmb1.vue?vue&type=script&lang=js&"
export * from "./jzmb1.vue?vue&type=script&lang=js&"
import style0 from "./jzmb1.vue?vue&type=style&index=0&id=907d6466&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "907d6466",
  null
  
)

export default component.exports