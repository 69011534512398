<template>
<div class="scwj ">
    <div class="radio-box" @change="upChange">
        <el-radio v-model="radioType" :label="1">上传数字口扫文件</el-radio>
         <el-radio v-model="radioType" :label="2">寄出硅橡胶印模</el-radio> 
    </div>
    <div class="wenjscbox" v-if="radioType==1">
        <el-upload class="upload-demo" :action="baseURL" multiple accept=".zip,.rar" ref="schuanwj" :on-change="handleSuccess" :on-remove="remove" :limit="1" :file-list="fileList" :auto-upload="false" :data="{
                }">
            <!-- <img class="scimg" :src="flag?OK:NO"> -->
            <img class="scimg" :src="OK" alt="">
            <img class="close" :src="require('@/assets/close.png')" v-show="!flag">
            <!-- <span  class="close el-icon-close"  v-show="!flag"></span> -->
            <div class="el-upload__text" v-show="flag">点击上传口扫文件</div>
            <div class="el-upload__tip" slot="tip" v-if="flag">文件限制：最大100M，支持rar.zip格式</div>
            <div class="el-upload__tip" style="color:red" slot="tip" v-else>点击文件框即可删除上传文件</div>

        </el-upload>
        <div class="isupload" :class="{success:isUpload=='(上传成功)'}">{{isUpload}}</div>
    </div>
   <yjmx :radio="radioType" :binglft="binglft"  v-if="radioType==2"></yjmx>
     
     
     
      
</div>
</template>

<script>
import OSS from 'ali-oss';
import newsite from "@/views/newtangc/editsite";
import yjmx from "@/views/blgl/component/yjmx";


export default {
  components: {
    newsite,
    yjmx
  },
  props:{
        binglft:{
          type:Boolean
      }
    },

    data() {
     
        return {
          
           
            uplist: [],
            newImg: require("@/assets/2.2病例详情/newfile.png"),
            fileImg: require("@/assets/2.2病例详情/file.png"),
            // 是否是新建,true不是,false是

            isNew: true,
            fileList: [],
            // 是否上传成功
            flag: true,
            OK: require("@/assets/3新建病例/上传文件.png"), //已上传
            NO: require("@/assets/3新建病例/PC-新建病例上传文件.png"), //未上传
            type: "",
            scwjadd: "Y",
            baseURL: "", // 上传文件路径默认地址
            newArr: {},
            yjmx: [],
            radioType: 1,
            isUpload: '',
            isOpen: true,
            fileName: '',
            // 是否是新建,true不是,false是
            token: {},
            isNew: true,
            fileList: [],
            // 是否上传成功
            flag: true,
            OK: require('@/assets/3新建病例/上传文件.png'), //已上传
            NO: require('@/assets/3新建病例/PC-新建病例上传文件.png'), //未上传
            type: '',
            scwjadd: 'Y',
            baseURL: '', // 上传文件路径默认地址
        };
    },
    watch: {

    },
    created(){
      
    },
    methods: {
   
        setupFileList() {
            this.$emit("setupFileList", this.fileList);
        },
        lxjsjy() {
            this.dialogVisible5 = true;
        },
      
        stringStr(dizAddress) {
            let str = ''
            if (dizAddress && dizAddress.length > 53) {
                str = dizAddress.substr(0, 53) + '...'
            } else {
                str = dizAddress
            }
            return str
        },
        yjmxChange(val) {
            if (this.yjmx.indexOf(val) != -1) {
                this.yjmx.splice(this.yjmx.indexOf(val), 1);
            } else {
                this.yjmx.push(val);
            }
            if (this.yjmx.length == 0) {
                this.mailType = 0;
                console.log('xxxxx')
            }
        },
        upChange() {
            if (this.radio == 2 && (!this.binglft)) {
                this.getSFById();
                this.get
            }
        },
        saveScanLog(params) {
            this.$http({
                url: 'caseInfo/saveScanLog',
                method: 'POST',
                data: params

            }).then((data1) => {

            }).catch(res => {

            })
        },
        // =======================\\
        schuawj() {
            this.$refs.schuanwj.submit();
        },
        // 判断文件格式
        ifType(file) {
            const fileExt = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
            const isJPG = fileExt == '.rar' || fileExt == '.zip'
            const isLt2M = file.raw.size / 1024 / 1024 < 100;
            if (!isJPG) {
                this.$message.error('上传文件只支持 rar/zip 格式!');
                return false
            }
            if (!isLt2M) {
                this.$message.error('上传文件最大100MB !');
                return false
            }
            return true;
        },
        // 改变事件
        handleSuccess(file, fileList) {

            console.log(fileList)
            if (!this.ifType(file)) {
                this.fileList = []
                return
            }
            this.fileList = fileList //需要上传的文件
            this.scwjadd = 'N'; //是否新建
            this.flag = false;
            //  if (!this.isNew) return;
            this.newLodImg();
        },
        remove() {
            this.isUpload = ''
            if (this.isOpen) {
                this.isOpen = false
                this.deleteimg();
                this.fileList = [];
                this.flag = true;
            }
        },
        newLodImg() {
            this.loadingInstance = this.Loading.service({
                text: '正在上传'
            });

            this.isUpload = ''
            var eightFormdata = new FormData();
            let uuid = new Date().getTime()
            eightFormdata.append('caseId', this.$store.state.binglId)
            // let   uuid=new Date().getTime()
            this.$http({
                    url: '/caseInfo/getCaseInfoById',
                    method: 'POST',
                    data: eightFormdata

                }).then((data1) => {
                    let res = JSON.parse(data1.data.data);

                    this.caseNo = res.caseinfoId;
                    this.token.accessKeySecret = res.accessKeySecret
                    this.token.accessKeyId = res.accessKeyId
                    this.token.securityToken = res.securityToken
                    this.token.ossAddress = res.ossAddress
                    this.token.bucketName = res.bucketName;
                    console.log(this.token);
                    /* if (data.code == 200) {

                      console.log(data);

                    } */

                    const data = {
                        // 上传文件
                        file: this.fileList[0].raw,
                        // 病例 id
                        caseId: this.$store.state.binglId,
                        // 病例阶段
                        stageName: this.$store.state.stageName,
                        // 病例次数
                        stageCount: this.isNew ? this.$store.state.stageCount : this.$store.state.stageCount + 1,
                        // 文件序号
                        fileNumber: 12,
                        //是否新增
                        add: 'Y'
                    }
                    var client = new OSS({
                        region: 'oss-' + this.token.ossAddress, // oss地址
                        accessKeyId: this.token.accessKeyId, // 通过阿里云控制台创建的AccessKey ID。
                        accessKeySecret: this.token.accessKeySecret, // 通过阿里云控制台创建的AccessKey Secret。
                        stsToken: this.token.securityToken,
                        bucket: this.token.bucketName, // 仓库名字
                        useFetch: true, // 支持上传大于100KB的文件
                        // secure:true // 返回的url为https
                    });
                    let stageName = this.$store.state.stageName;
                    let stage = ''
                    if (stageName == 1) {
                        stage = 'FirstDesign';
                    } else if (stageName == 2) {
                        stage = 'MidTermRestart';
                    } else if (stageName == 3) {
                        stage = 'FinalAdjustment';
                    } else if (stageName == 4) {
                        stage = 'AfterProduction';
                    }
                    console.log(this.caseNo);
                    var fileName = this.caseNo + '/' + stage + '/' + this.$store.state.stageCount + '/SCAN/' + new Date().getTime() + data.file.name.substr(data.file.name.indexOf('.'))
                    this.fileName = fileName
                    this.saveScanLog({
                        uuid,
                        outerno: this.caseNo,
                        file: this.fileName,
                        stage: this.$store.state.stageName == 1 ? 1 : 2, //1新建 2重启
                        type: 2
                    })

                    client.multipartUpload(fileName, data.file).then(res => {
                        this.isUpload = '(上传成功)'
                        this.saveScanLog({
                            uuid,
                            outerno: this.caseNo,
                            file: fileName,
                            stage: this.$store.state.stageName == 1 ? 1 : 2, //1新建 2重启
                            type: 1
                        })
                        let params = {
                            // 病例 id
                            caseId: this.$store.state.binglId + '',
                            // 病例阶段
                            stageName: this.isNew ? this.$store.state.stageName : 2,
                            // 病例次数
                            stageCount: this.$store.state.stageName == 2 ?  this.$store.state.stageCount + 1:this.$store.state.stageCount ,
                            // 文件序号
                            fileNumber: '12',
                            //是否新增
                            add: 'Y',
                            ossfilePath: res.name,
                            fileName: data.file.name,

                        }
                        var eightFormdata = new FormData();
                        for (var k in params) {
                            eightFormdata.append(k, params[k]);
                        }
                        this.$http({
                            url: "/caseInfo/stepEightScan",
                            method: "POST",
                            data: eightFormdata,
                        }).then(({
                            data
                        }) => {
                            this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                this.loadingInstance.close();

                            });
                            /* this.$message({
                                message: "保存成功上传成功",
                                offset:640,
                                type: "success",
                                duration: 4000,

                            }); */

                        }).catch(err => {
                            this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                this.loadingInstance.close();

                            });
                            this.isUpload = ' (上传失败)'

                            this.$message({
                                message: '保存失败上传失败',
                                type: 'error',
                                duration: 1500
                            })
                            console.log(err)
                        })

                    }).catch(err => {
                        this.isUpload = ' (上传失败)'

                        this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            this.loadingInstance.close();

                        });
                        this.$message({
                            message: '上传失败',
                            type: 'error',

                        })

                        console.log(err);
                    })
                })
                .catch((err) => {
                    this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        this.loadingInstance.close();

                    });
                    this.isUpload = ' (上传失败)'

                    this.$message({
                        message: 'OSS信息获取失败',
                        type: 'error',

                    })
                    console.log(err);
                });
        },
        deleteimg() {
            // 删除 图片 接口
            this.$http({
                url: "/caseInfo/delPhoto",
                method: "POST",
                data: {
                    // 病例 id
                    caseId: this.$store.state.binglId,
                    // 病例阶段
                    stageName: this.$store.state.stageName,
                    // 病例次数
                    stageCount: this.$store.state.stageCount,
                    // 文件序号
                    fileNumber: 12
                }
            }).then(({
                data
            }) => {
                this.isOpen = true
            }).catch(err => {
                this.isOpen = true
                console.log(err)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.footer {
    margin-top: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.space {
    margin-right: 10px;
}

.qjxl {
    .title {
        margin-top: 20px;
        margin-bottom: 16px;
    }

    span,
    .title {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #ff0000;
    }

    .jjdz {
        display: flex;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #666666;
        margin-bottom: 16px;

        .space {
            margin-left: 10px;
        }

        &>div:first-of-type {
            width: 90px;
        }
    }

    .order-btn {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;

        .active {
            color: #1275d2;
            text-decoration: underline;
        }

        &>div {
            width: auto !important;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}

.qjsj {
    padding-left: 12px;
    width: 300px;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #1275d2;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        border: 8px solid transparent;
        border-top: 8px solid #1275d2;
        right: 9px;
        top: 40%;
        //transform: translateY(-50%);
    }

    .qjsj-box {
        left: 0;
        width: 560px;
        height: 160px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        position: absolute;
        background: #ffffff;
        top: 42px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;

        .qjsj-day {
            padding: 16px 0;
            display: flex;
            justify-content: space-around;
            align-items: center;

            div {
                cursor: pointer;
                width: 160px;
                height: 28px;
                font-size: 14px;
                border: 1px solid #1275d2;
                font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                font-weight: normal;
                color: #333333;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .active {
                background: #1275d2;
                color: #ffffff;
            }
        }

        .qjsj-line {
            margin: auto;
            width: 540px;
            height: 1px;
            background: #999999;
        }

        .qjsj-hour {
            margin-right: -18px;
            flex-shrink: 1;
            overflow-y: scroll;
            display: flex;
            padding-left: 16px;
            align-items: center;
            flex-wrap: wrap;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-top: 16px;
                margin-bottom: 16px;
                margin-right: 18px;
                width: 90px;
                height: 24px;
                border-radius: 12px;
                border: 1px solid #1275d2;
                display: flex;
                justify-items: center;
                align-items: center;
                font-size: 12px;
                font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                font-weight: normal;
                color: #333333;

                p {
                    margin-top: 0;
                }
            }

            div:nth-of-type(5n) {
                margin-right: 0;
            }

            .active {
                background: #1275d2;
                color: #ffffff;
            }
        }
    }
}

.close::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
}

.click-select {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-right: 35px;
    background: white;
    z-index: 10;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: 1px solid #1275d2;

    &.active::after {
        top: 19%;
        border: 8px solid transparent;
        border-bottom: 8px solid #1275d2;
    }

    &::after {
        content: "";
        position: absolute;
        border: 8px solid transparent;
        border-top: 8px solid #1275d2;
        right: 9px;
        top: 40%;
    }
}

/deep/ .express {
    height: 448px;

    .el-input__inner {
        margin-left: 1px;
        margin-top: 1px;
        width: 300px;
        height: 64px;
        border-radius: 6px;
        border: transparent;
        color: transparent;
    }

    .edit-box {
        cursor: pointer;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;
        display: flex;
        align-items: center;
        margin-left: 16px;
        cursor: pointer;
    }

    .code {
        margin-top: 32px;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #0075f6;
    }

    .detail {
        align-self: flex-start;
        margin-top: 32px;
        margin-left: 0;
    }

    .text {
        margin-top: 2px;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #666666;
    }
}

.copy-icon {
    cursor: pointer;
    align-self: end;
}

.address {
    margin-top: 32px;
    display: flex;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
}

/deep/ .tangcform {
    padding-top: 20px;
}

.gray {
    background: gray !important;
}

.blue-btn {
    cursor: pointer;
    width: 96px;
    height: 28px;
    background: #1275d2;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
}

.img-box {
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-right: 9px;

    img {
        width: 100%;
        display: block;
    }
}

.table {
    /deep/ th {
        border-bottom: #1275d2 2px solid;
    }

    /deep/ td {
        cursor: pointer;
    }

    // 修改鼠标经过表格的颜色
    /deep/ .el-table tbody tr:hover>td {
        // 可以选择隐藏
        background-color: transparent !important;
        opacity: 0.8;
    }
}

/deep/ .el-dialog__body {
    padding: 0;
}

/deep/ .el-dialog__header {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}

/deep/ .el-dialog__title {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
    line-height: 32px;
    padding: 0;
}

/deep/.el-dialog__headerbtn {
    top: 8px;
}

/deep/.el-dialog__header {
    height: 32px;
    background: #1275d2;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.dialog-div {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    line-height: 20px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.canel {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #0075f6;
}

.submit {
    cursor: pointer;
    margin-right: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 24px;
    background: #1275d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
    line-height: 20px;
}

.submit-btn {
    margin-right: 0;
}

.dialog-box {
    padding-left: 24px;

    p {
        margin-top: 8px;
    }

    .qjsj {
        /deep/.el-input__inner {
            margin-top: 8px;
            width: 400px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid #1275d2;
        }
    }

    /deep/ .el-icon-arrow-up:before {
        color: #1275d2;
    }
}

.div-text {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    line-height: 20px;
    margin-top: 32px;
}

.search-box {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-left: 24px;
    padding-right: 15px;
    padding-top: 32px;
    padding-bottom: 36px;

    /deep/ .el-input {
        width: auto;
    }

    /deep/.el-input__inner {
        margin-top: 0;
        width: 240px;
        height: 32px;
        background: #ffffff;
        border-radius: 20px;
        border: 1px solid #d9d9d9;
    }

    /deep/ .el-input__icon {
        line-height: 32px;
    }

    .btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96px;
        height: 32px;
        background: #1275d2;
        border-radius: 4px;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #ffffff;
    }
}

.del {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ff0000;
    line-height: 20px;
    text-decoration: underline;
}

.xuanze {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    line-height: 20px;
    text-decoration: underline;
}

.table-box {
    width: 772px;
    margin-left: 76px;
    margin-top: 40px;

    /deep/ .el-table {
        background: transparent;

        .el-table__header {
            background: transparent;
        }

        th {
            border-bottom: 2px #1a95eb solid;
        }

        th,
        tr {
            background-color: transparent;
            font-size: 14px;
            font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #333333;
            line-height: 20px;
        }
    }
}

.blue-bg {
    background-color: #1275d2;
    color: #ffffff !important;
}

.detail-address {
    margin-left: 20px;
}

.detail {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    margin-left: 76px;
    display: flex;
    align-items: center;

    span {
        color: #ff0000;
    }
}

.radio-div {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 40px;

    div {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #666666;
        cursor: pointer;
        margin-right: 32px;
        padding: 0 20px;
        height: 28px;
        border-radius: 16px;
        border: 1px solid #1275d2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.radio-box {
    padding-top: 42px;
    margin-left: 76px;
    margin-bottom: 40px;
}

.scwj {
    height: 550px;
    position: relative;
}

.wenjscbox {
    position: relative;

    left: 76px;

    .upFile {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .fileName {
            max-width: 160px;
            padding-right: 10px;
            height: 20px;
            font-size: 14px;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #333333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .btn {
            cursor: pointer;
            color: red;
        }
    }
}

.wenjscbox {
    width: 600px;

    img {
        width: 26px;
        height: 26px;
        margin-right: 8px;
    }

    .btn {
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;
        line-height: 20px;
    }
}

.show-box {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0e, 0.5);
    z-index: 9999;
    top: 0;
    left: 0;

    &>div {
        position: absolute;
        background: #f9f9f9;
        width: 500px;
        height: 200px;
        left: 50%;
        top: 50%;

        div {
            margin-top: 50px;

            display: flex;
            align-items: center;
            justify-content: center;

            p {
                display: inline-block;
                max-width: 150px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        transform: translate(-50%, -50%);

        .top {
            margin-top: 0;
            height: 20px;
            background: #333333;
        }

        .footer {
            position: absolute;
            bottom: 20px;
            display: flex;
            left: 50%;
            transform: translateX(-50%);

            &>div {
                margin-right: 50px;
                background: #1175d2;
                border-radius: 5px;
                padding: 5px;
                color: #f9f9f9;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }
}

.disabled {
    color: #666666;
}

.my-select {
    /deep/ .el-select-dropdown__list {
        padding-bottom: 20px;
    }
}

/deep/ .el-radio__inner {
    width: 20px;
    height: 20px;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    background: #fff !important;
    width: 20px;
    height: 20px;

    &::after {
        width: 10px;
        height: 10px;
        background: #1175D2;
        border-radius: 50%;
    }
}

.jkd {
    /deep/ .el-dialog__close {
        color: #ffffff;
    }

    /deep/.el-dialog__body {
        overflow: inherit;
    }
}
</style><style lang="less" scoped>
/deep/.upload-demo {
    .el-upload-list {
        position: absolute;
        left: 50%;
        top: 180px;
        transform: translateX(-50%);
        width: 100%;

        .el-upload-list__item-status-label,
        .el-icon-close {
            top: -181px;
            right: 0;
            opacity: 0;
            display: block;
        }

        .el-upload-list__item-status-label {
            .el-icon-upload-success {
                width: 604px;
                height: 300px;
            }
        }

        .el-icon-close {
            width: 604px;
            height: 300px;
        }
    }
}

.scwj {
    height: 682px;
    position: relative;
}

/deep/.wenjscbox {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 300px;
    border: 2px dashed #d9d9d9;
    border-radius: 6px;

    &:hover {
        border-color: #1175d2;
    }

    .el-upload__tip {
        text-align: center;
        font-size: 16px;
        color: #333333;
    }

    .isupload {

        position: absolute;
        top: 272px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: red;
    }

    .success {
        color: green;
    }
}

/deep/ .el-upload-list__item {
    text-align: center;
}

/deep/ .el-upload-list__item-name {
    margin-right: 0;
    font-size: 20px;
    white-space: normal;
    line-height: 30px;

}

/deep/.el-upload {
    position: relative;
    width: 600px;
    height: 300px;

    .el-upload-dragger {
        width: 100%;
        height: 100%;
        border: none;
    }

    .scimg {
        width: 90px;
        height: auto;
        position: absolute;
        left: 51%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .el-upload__text {
        position: absolute;
        left: 50%;
        top: 70%;
        transform: translate(-50%, -50%);

        color: #f99400;
        font-size: 16px;

    }

}

.close {
    width: 40px;
    position: absolute;
    right: 246px;
    top: 86px;
    color: #333333;
    font-size: 40px;

}

/deep/ .el-radio__inner {
    width: 20px;
    height: 20px;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    background: #fff !important;
    width: 20px;
    height: 20px;

    &::after {
        width: 10px;
        height: 10px;
        background: #1175D2;
        border-radius: 50%;
    }
}

.radio-box {
    padding-top: 42px;
    margin-left: 76px;
    margin-bottom: 40px;
}
</style>
